import React from "react";

export default function PrivacyPage() {
  return (
    <div style={{width:'90%', textAlign:'center', paddingLeft:40, paddingRight:40, paddingTop:20, paddingBottom:20}}>
      <h1 className="font-semibold">ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</h1>
<p style={{textAlign: 'justify'}}>
Үйлчилгээний нөхцөл нь Үйлчилгээний нөхцөлтэй танилцаж, зөвшөөрсөнөөр үйлчилгээтэй холбоотой харилцаанд оролцох эрх, үүрэг, хариуцлагыг хүлээхээр Талууд харилцан тохиролцсонд тооцогдоно.
<br />
<b>Нэг. Ерөнхий зүйл</b>
<br />
1.1 Үйлчилгээний нөхцөл нь нэг талаас SAIN системийн аюулгүй, найдвартай байдлыг ханган Хувийн мэдээллийг хадгалах, хамгаалах Үйлчилгээ үзүүлэгчийн, нөгөө талаас Үйлчилгээг ашиглан өөрийн бизнесийн үйлчилгээг боловсруулах, хамгаалах Хэрэглэгчийн харилцааг зохицуулна.
<br />
1.2 Үйлчилгээний нөхцөл нь Хэрэглэгч Үйлчилгээг хүлээн зөвшөөрч, SAIN  системд бүртгүүлсэн өдрөөс хүчин төгөлдөр үйлчилнэ.
<br />
1.3 Үйлчилгээ үзүүлэгчийн сервер компьютер нь “Үндэсний дата төвийн”-ийн нууцлал, найдвартай ажиллагаагаар хангагдсан дэд бүтцийг ашиглана.
<br />
1.4 SAIN систем нь оюуны өмчийн эрхтэй бүтээгдэхүүн бөгөөд Монгол Улсын “Оюуны өмчийн тухай” хуулиар хамгаалагдана.
<br />
1.5 SAIN систем болон Үйлчилгээтэй холбоотой вэб сайт, түүний мэдээлэл, баримт бичиг нь Үйлчилгээ үзүүлэгчийн өмч юм.
<br />
1.6 Үйлчилгээтэй холбоотой техник, технологи, хадгалагдаж буй бүх мэдээлэл нь Үйлчилгээ үзүүлэгчийн нууц бөгөөд Монгол Улсын “Байгууллагын нууцын тухай” хуулиар зохицуулагдана.
<br />
<b>Хоёр. Хэрэглэгчийн эрх, үүрэг</b>
<br />
2.1 Хэрэглэгч SAIN системд бүртгүүлэхийн өмнө Үйлчилгээний нөхцөлтэй танилцаж, зөвшөөрснөөр дагаж мөрдөх үүрэг хүлээнэ.
<br />
2.2 Хэрэглэгч нь бусдыг төлөөлж эсвэл бусдад Үйлчилгээ хүргэх зорилгоор Үйлчилгээг ашиглах бол тухайн хувь хүн, хуулийн этгээдийн өмнөөс Үйлчилгээний нөхцөлтэй танилцаж, зөвшөөрсөнөөр төлөөлж буй этгээдийн өмнөөс хариуцлага хүлээнэ.
<br />
2.3 Өөрийн нууц үгийг алдалгүй, найдвартай хадгалах нь хэрэглэгчийн үүрэг байна.
<br />
2.4 Өөрийн нууц үгээ өөрчлөх, шинэчлэх нь хэрэглэгчийн хариуцлага байна.
<br />
2.5 Өөрийн нууц үгийг алдсанаас болон мэдээллийн нууцаа хадгалж чадаагүйгээс үүдэн гарсан асуудлын хариуцлагыг Үйлчилгээ үзүүлэгч хүлээхгүй.
<br />
2.6 Хэрэглэгч нь Үйлчилгээ үзүүлэгчийн компьютерийн сүлжээ, сервер компьютерт хууль бусаар халдах, Үйлчилгээнд эвдрэл, саатал учруулах оролдлого хийхгүй байх үүрэгтэй.
<br />
2.7 Хэрэглэгч нь Үйлчилгээг ашиглан хийсэн бүхий л үйлдэлд хариуцлага хүлээнэ.
<br />
2.8 Үйлчилгээний нууцлал, аюулгүй байдал, найдвартай ажиллагааны талаар санал, гомдлыг Үйлчилгээ үзүүлэгчид гаргана.
<br />

<b>Гурав. Үйлчилгээ үзүүлэгчийн эрх, үүрэг</b>
<br />
3.1 Үйлчилгээ үзүүлэгч нь SAIN системийн арчилгаа, аюулгүй найдвартай байдал, Хувийн мэдээлэл болон Үйлчилгээтэй холбоотой мэдээллийг хадгалах, хамгаалах, нөөцлөх зэрэг үйл ажиллагааны жигд байдлыг хангах үүрэгтэй.
<br />
3.2 Үйлчилгээ үзүүлэгч нь 24/7 (24 цагийн турш, долоо хоногийн бүх өдөр) нөхцлөөр Үйлчилгээг Хэрэглэгчдэд хүргэнэ.
<br />
3.3 Үйлчилгээ үзүүлэгч нь Хувийн мэдээлэлд шууд хандалт хийхгүй бөгөөд Хэрэглэгчийн зөвшөөрсөн урилгын дагуу Хувийн мэдээлэлд хандаж тусламж, зөвлөгөө өгнө.
<br />
3.4 Үйлчилгээ үзүүлэгч нь Хэрэглэгчийн байгаа байрлалд биеэчлэн очиж тусламж, дэмжлэг үзүүлэхгүй.
<br />
3.5 Үйлчилгээ үзүүлэгч нь техникийн өргөтгөл, програмын өөрчлөлт, шинэчлэлт хийх тохиолдолд Үйлчилгээнд түр хугацааны зогсолт хийнэ. Энэ тохиолдолд Хэрэглэгчийн имэйл хаяг болон вэб сайтаар дамжуулан урьдчилан мэдэгдэнэ.
<br />
3.6 Техникийн өргөтгөл, програмын өөрчлөлт, шинэчлэлт хийсэнтэй холбоотой Хувийн мэдээлэл өөрчлөгдсөн, устсан тохиолдолд хариуцлагыг Үйлчилгээ үзүүлэгч хүлээх бөгөөд асуудлыг шийдвэрлэхэд Талууд хамтран ажиллана.
<br />
3.7 Үйлчилгээ үзүүлэгч нь Хэрэглэгчийн интернет холболтоос үүдэлтэй асуудлын хариуцлагыг хүлээхгүй.
<br />
3.8 Хуулиар тусгайлан эрх олгогдсон байгууллага, тэдгээрийн ажилтан нь Хувийн мэдээлэлд хандаж албаны хэрэгцээнд мэдээлэл авах эрхтэй.
<br />
3.9 Үйлчилгээ үзүүлэгч нь Үйлчилгээний нөхцлийг өөрчлөн, шинэчлэх эрхтэй. Үйлчилгээний нөхцөлд өөрчлөлт хийсэн даруйд өөрчлөлтийн мэдэгдлийг Хэрэглэгчийн и-мэйл хаяг болон вэб сайтаар дамжуулан мэдэгдэнэ.
<br />

<b>Дөрөв. Асуудал, маргаан шийдвэрлэх</b>
<br />
4.1 Хэрэглэгч нь Үйлчилгээг ашиглахтай холбоотой тусламжийг системээс авах боломжтой.
<br />
4.2 Үйлчилгээ үзүүлэгч нь Үйлчилгээг ашиглахтай холбоотой санал, гомдол, хүсэлтийг info@sainlombard.mn и-мэйл хаяг болон 75753232 утсаар (ажлын өдрүүдэд 09:00 - 17:00 цагийн хооронд) хүлээн авч, шийдвэрлэнэ.
<br />
4.3 Хэрэглэгч нь Үйлчилгээний нөхцөлд хүлээсэн үүргээ зөрчсөн, Үйлчилгээг хууль бусаар ашигласан, Үйлчилгээ үзүүлэгчийн үйл ажиллагаанд болон бусад Хэрэглэгчдэд хохирол учруулсан нь батлагдвал Хэрэглэгчийн нэвтрэх эрхийг цуцлана.
<br />
4.4 Үйлчилгээний тасалдал нь байгалийн давагдашгүй хүчин зүйл (гал, үер, газар хөдлөлт, аянга болон тэсрэлт) эсвэл төрийн эрх бүхий байгууллагаас гаргасан хуулийн зохицуулалт, журам, хэрэгжүүлсэн үйл ажиллагаа, ажил хаялт, дайн, террорист халдлага, гоц халдварт өвчин зэрэгтэй холбоотой бол Үйлчилгээг тасалдсанд тооцохгүй бөгөөд Үйлчилгээ үзүүлэгч хариуцлага хүлээх үндэслэл болохгүй.
<br />
4.5 Үйлчилгээтэй холбоотой санал, гомдол, асуудал, маргааныг Талууд эв, зүйгээр харилцан зөвшилцөх замаар шийдвэрлэнэ. Хэрэв шийдвэрлэх боломжгүй бол Монгол Улсын хууль, тогтоомжид заасны дагуу шийдвэрлүүлнэ.
</p>
      <h1>МЭДЭЭЛЛИЙН АЮУЛГҮЙ БАЙДЛЫН БОДЛОГО</h1>
      <p style={{textAlign: 'justify'}}>
        Бид өөрийн үйл ажиллагаанд ашиглагдаж буй бүх сонирхогч талуудын
        мэдээллийн бүрэн бүтэн, нууцлалтай, хүртээмжтэй байдлыг хангахын тулд
        мэдээллийн аюулгүй байдлын менежментийн тогтолцооны стандартын
        шаардлагуудыг хангах үйл явц, журмын хэрэгжилтийг хэмжигдэхүйц үр дүнд
        үндэслэн тасралтгүй сайжруулна.
      </p>
      <p style={{textAlign: 'justify'}}>
        Харилцагчийн бүх мэдээллийг байгууллагын нууцад хамааруулж, биет
        хөрөнгөтэй ижил түвшинд авч үзэн, аюулгүй байдлыг хангахын тулд
        холбогдох хууль, зохицуулалтын шаардлага, бизнесийн ёс зүй, үнэт
        зүйлсийг чанд сахин ажиллаж байна.
      </p>
      <p style={{textAlign: 'justify'}}>
        Бид мэдээллийн аюулгүй байдалтай холбоотой аливаа эрсдэлийг хамгийн бага
        байлгахын тулд тогтмол давтамжтай үнэлж, шаардлагатай хариу арга хэмжээ,
        хяналтыг хэрэгжүүлнэ.
      </p>
      <h1>МЭДЭЭЛЛИЙН АЮУЛГҮЙ БАЙДАЛ</h1>
      <p style={{textAlign: 'justify'}}>
        Манай байгууллага нь мэдээллийн аюулгүй байдлыг хангах зорилгоор
        ISO/IEC27001:2013 стандартыг нэвтрүүлэхээр мэргэжлын сургалтын
        байгууллагаас сургалт, зөвлөх үйлчилгээг авч мэдээлэл алдагдахаас
        урьдчилан сэргийлэх бүх ажлуудыг хийж гүйцэтгэн, байгууллагын дотоод
        болон гадаад аюулгүй ажиллагааны журмуудыг батлан хэрэгжүүлэн ажиллаж
        байна.
      </p>
      <p style={{textAlign: 'justify'}}>
        Түүнчлэн байгууллагын зүгээс ажил хэргийн тасралтгүй чанарыг хангах,
        эрсдлийг багасгах, харилцагч нарын аюулгүй байдал, бизнесийн боломжийг
        нэмэгдүүлэхийн тулд хийж байгаа мэдээллийг олон янзын аюул заналхийлэл,
        мэдээллийн системд зөвшөөрөлгүй хандах, мэдээллийг ашиглах ил болгох,
        өөрчлөх, хуулах, устгах, мэдээллийн системийн үйл ажиллагааг
        тасалдуулах, хянах зэргээс хамгаалж мэдээллийн нөөцийн аюулгүй байдлыг
        хамгаалах техникийн, хууль эрх зүйн, програмчлалын Техникийн аргуудыг
        ашиглан ажиллаж байна.
      </p>
      <p style={{textAlign: 'justify'}}>
        Мэдээллиййн нөөцийн аюулгүй байдлыг хамгаалах сүлжээний аюулгүй байдлыг
        хангахдаа сүлжээний эрсдлийг тооцох, аль нэг стандарт, дүрэм журмыг
        дагаж мөрдсөнөөр нууцлал хамгаалалт хангагдсан гэж найдахгүй байх,
        хүмүүсээс шалтгаалан учирч болох эрсдлийг тооцох, олон зүйлд чиглэсэн
        олон хандалтын эрхтэй байх, програмын сул талуудыг нөхөх арга ажиллагааг
        тогмтол авч хэрэгжүүлэх, түүх бичилтүүдийг шүүх, мониторинг хийх,,
        K.I.S.S (keep it simple stupid) зарчмуудыг баримтлан ажиллаж байна.
        Харилцагчийн бүх мэдээллийг байгууллагын нууцад хамааруулж, биет
        хөрөнгөтэй ижил түвшинд авч үзэн, аюулгүй байдлыг хангахын тулд
        холбогдох хууль, зохицуулалтын шаардлага, бизнесийн ёс зүй, үнэт
        зүйлсийг чанд сахин ажиллаж байна.
      </p>
      <p style={{textAlign: 'justify'}}>
        Бид мэдээллийн аюулгүй байдалтай холбоотой аливаа эрсдэлийг хамгийн бага
        байлгахын тулд тогтмол давтамжтай үнэлж, шаардлагатай хариу арга хэмжээ,
        хяналтыг хэрэгжүүлнэ.
      </p>
    </div>
  );
}
