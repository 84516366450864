import { Routes, Route } from 'react-router-dom';
import './App.css';
import Qpay from './qpay';
import CQpay from './cqpay';
import PrivacyPage from './privacy';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/:gereecode' element={<Qpay />} />
        <Route path='/c/:gereecode' element={<CQpay />} />
        <Route path='/privacy' element={<PrivacyPage />} />
      </Routes>
    </div>
  );
}


function Home(){
  return(
    <div>
      <img src="/logo.jpg" width="100%" alt="" />
      <h3>Сайн Ломбард</h3>
      <h3>Утас: 75753232</h3>
    </div>
  )
}

export default App;
